import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { constants } from '../../helpers/constants';
import useGetActivities from '../../hooks/useGetActivities';
import { ImageFixedAO } from '../Image_fixed_aspect_ratio';
import { numFormatter } from '../../lib/numberFormatter';
import { shortenAddress } from '../../helpers/address';
import { OnEnterScreen } from '../onEnterScreen';
import { LoadingSpinner } from '../loadingSpinner';
import { ActivitySkeleton } from '../Skelaton';
import { GiCardExchange } from 'react-icons/gi';
import { Tooltip } from '@mui/material';
import { useAccount } from 'wagmi';
import { setModal } from '../../redux/counterSlice';
import { useDispatch } from 'react-redux';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { getCollectionPath, getItemPath } from '../../helpers/path';
import { getCdnUrl } from '../../lib/cdnUrl';
import Icon, { ChainIcon } from '../Icon';
import { CollectionImage, ShowUser, UserImage, UserLink } from '../userImage';
import getChainName, { getTokenName } from '../../helpers/getChainName';
import { getSupportedChain } from '../../helpers/getSupportedChain';
import { Dropdown_container } from '../dropdown/dropdown_container';
import DropDownItemNetworks from '../dropdown/DropDownItemNetworks';
function shortenText(text, maxStartLength = 6, maxLengthForMobile = 3) {
  if (!text) {
    return '';
  }

  const numberMatch = text.match(/#\d+$/);
  const numberPart = numberMatch ? numberMatch[0] : '';

  const textWithoutNumber = numberPart ? text.slice(0, -numberPart.length) : text;

  const isMobile = window.innerWidth <= 900; // You can adjust the mobile width threshold

  // Determine the maximum length allowed based on the device type
  const maxAllowedLength = isMobile ? maxLengthForMobile : maxStartLength;

  // If the text length is shorter than or equal to maxAllowedLength, return it as is with the number part
  if (textWithoutNumber.length <= maxAllowedLength) {
    return `${textWithoutNumber}${numberPart}`;
  }

  // Get the first maxAllowedLength characters of the text and append the number part
  const startPart = textWithoutNumber.slice(0, maxAllowedLength);
  return `${startPart}...${numberPart}`;
}

const Activity_item = ({ address, collectionAddress, itemId, showChainFilter = false }) => {
  const dispatch = useDispatch();
  const { address: walletAddress, isConnected } = useAccount();
  const [filterVal, setFilterVal] = useState('all');
  const [shouldLoad, setShouldLoad] = useState({});
  const [page, setPage] = useState(1);
  const [lastData, setLastData] = useState(null);
  const [selectedChains, setSelectedChains] = useState([]);
  const filterData = ['sale', 'listing', 'transfer', 'bid', 'like', 'changed'];
  const { data: swr } = useGetActivities(
    'createdAt:desc',
    50,
    page,
    filterVal,
    address,
    collectionAddress,
    itemId,
    selectedChains,
  );
  const { results: allActivities, page: lastPage, totalPages, isError } = swr || {};

  useEffect(() => {
    if (allActivities) setLastData((prev) => [...(prev || []), ...allActivities]);
  }, [allActivities]);

  useEffect(() => {
    if (lastPage < totalPages) setPage(lastPage + 1);
  }, [shouldLoad]);

  useEffect(() => {
    setPage(1);
  }, [filterVal]);

  return (
    <>
      {/* <!-- Activity Tab --> */}
      <div className='tab-pane fade'>
        {/* <!-- Records / Filter --> */}
        <div className='flex flex-wrap'>
          <div className='w-full'>
            <h3 className='mb-4 font-display font-semibold text-jacarta-500 dark:text-white'>
              Filters
            </h3>
            <div className='mb-1.5 flex w-full items-center justify-between'>
              <div className='flex w-full flex-wrap items-center space-x-1.5'>
                {filterData.map((eventType) => {
                  return (
                    <button
                      className={
                        filterVal === eventType
                          ? 'rounded-xl group mb-1.5 inline-flex items-center border border-jacarta-100 border-transparent bg-accent px-4 py-3 text-white dark:border-jacarta-600 dark:border-transparent'
                          : 'rounded-xl group mb-1.5 inline-flex items-center border border-jacarta-100 bg-white px-4 py-3 hover:border-transparent hover:bg-accent hover:text-white dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:hover:border-transparent dark:hover:bg-accent'
                      }
                      key={eventType}
                      onClick={() => {
                        if (filterVal === eventType) {
                          setFilterVal('all');
                          return;
                        }
                        setFilterVal(eventType);
                      }}
                    >
                      <Tooltip title={eventType}>
                        <svg
                          className={
                            filterVal === eventType
                              ? 'icon mr-2 h-4 w-4 fill-white'
                              : 'icon mr-2 h-4 w-4 fill-jacarta-700 group-hover:fill-white dark:fill-white'
                          }
                        >
                          {eventType === 'changed' ? (
                            <GiCardExchange />
                          ) : (
                            <use xlinkHref={`/icons.svg#icon-${eventType}`} />
                          )}
                        </svg>
                      </Tooltip>
                      <span className='text-2xs font-medium capitalize'>{eventType}</span>
                    </button>
                  );
                })}
              </div>
              {showChainFilter && (
                <Dropdown_container
                  name={'Chains'}
                  className={'!mb-1.5 !mt-0 ml-1.5 w-fit !min-w-fit self-end'}
                >
                  <DropDownItemNetworks
                    selectedChainIds={selectedChains}
                    onChange={(val) => {
                      setLastData(null);
                      setSelectedChains(val);
                    }}
                  />
                </Dropdown_container>
              )}
              {/* </div> */}
            </div>
          </div>
          {/* <!-- Records --> */}
          <div className='scrollbar-custom w-full overflow-x-auto'>
            <div
              role='table'
              className='w-full min-w-[736px] rounded-lg border border-jacarta-100 bg-white text-sm dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white'
            >
              <div className='flex rounded-t-lg bg-jacarta-50 dark:bg-jacarta-600' role='row'>
                {['Type', 'Items', 'Price', 'From', 'To', 'Date', { l: '', w: 'w-[60px]' }].map(
                  (cell, i) => {
                    let width = 'w-1/6';
                    let label = cell;
                    if (typeof cell == 'object') {
                      label = cell.l;
                      width = cell.w;
                    }
                    return (
                      <div key={i + 'he'} className={`${width} py-3 px-4`} role='columnheader'>
                        <span className='w-full overflow-hidden text-ellipsis text-jacarta-700 dark:text-jacarta-100'>
                          {label}
                        </span>
                      </div>
                    );
                  },
                )}
              </div>
              {!lastData && !allActivities && <ActivitySkeleton count={10} />}
              {lastData?.map((activity, i) => {
                let {
                  collectionAddress,
                  itemId,
                  createdAt,
                  eventType,
                  price,
                  from,
                  to,
                  metadata,
                  collections: collectionInfo,
                  fromUser,
                  toUser,
                  txHash,
                  toCollection,
                  fromCollection,
                  item,
                  order,
                } = activity;
                if (filterVal !== eventType && filterVal !== 'all') return null;
                const { address, chain, thumbnailAPI, isDNFT, pathName, profilePhotoPath } =
                  collectionInfo;
                const now = new Date().getTime();
                const createdTime = new Date(createdAt).getTime();
                const diffMs = now - createdTime;
                const diffMins = Math.round(diffMs / 60000);
                const diffHours = Math.round(diffMs / 3600000);
                const diffDays = Math.round(diffMs / 86400000);
                const diffWeeks = Math.round(diffDays / 7);
                const diffMonths = Math.round(diffDays / 30);
                const diffYears = Math.round(diffDays / 365);
                metadata = JSON.parse(item.metadata);
                let image;

                if (isDNFT) {
                  image = thumbnailAPI + itemId;
                } else if (
                  collectionInfo?.mirrorAddress &&
                  collectionInfo?.contractType === 'DN404'
                ) {
                  image = collectionInfo?.profilePhotoPath
                    ? getCdnUrl(collectionInfo?.profilePhotoPath)
                    : '/images/no_image.png';
                } else {
                  image = item?.imageFtpPath
                    ? constants.cdn.path +
                      item.imageFtpPath +
                      constants.cdn.detail +
                      constants.cdn.version
                    : metadata?.image;
                }
                if (address === to && eventType === 'bid') {
                  image = getCdnUrl(profilePhotoPath, 'thumbnail', collectionInfo?.updatedAt);
                }
                try {
                  image = image.replace('w-540', 'w-270');
                } catch (error) {}
                return (
                  <div className='flex' key={'actvt' + collectionAddress + i} role='row'>
                    <div
                      className='flex w-1/6 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                      role='cell'
                    >
                      <Tooltip
                        title={eventType}
                        classes={{
                          tooltip: 'capitalize',
                        }}
                      >
                        <span className='mx-auto'>
                          <svg className='icon h-6 w-6 fill-jacarta-700 dark:fill-white'>
                            {eventType === 'changed' ? (
                              <GiCardExchange size={24} />
                            ) : (
                              <use xlinkHref={`/icons.svg#icon-${eventType}`} />
                            )}
                          </svg>
                        </span>
                      </Tooltip>
                    </div>
                    <div
                      className='flex w-1/6 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                      role='cell'
                    >
                      {/*<span className="mr-2 lg:mr-4">{id}</span>*/}
                      <figure className='relative mr-2 w-8 shrink-0 self-start lg:mr-5 lg:w-12'>
                        <ImageFixedAO image={image} alt={collectionAddress} bottomRounded />
                        <div className='absolute -bottom-1 -right-1'>
                          <ChainIcon name={chain} tooltip={getChainName(chain)} type={'chain'} />
                        </div>
                      </figure>
                      <Link
                        href={
                          toCollection
                            ? getCollectionPath(chain, pathName)
                            : getItemPath(chain, collectionAddress, itemId)
                        }
                        key={'actvt' + collectionAddress + i}
                      >
                        <a>
                          <Tooltip title={item?.name}>
                            <span
                              className='font-display text-sm font-semibold dark:text-white'
                              data-testid='activity-item-address'
                            >
                              {shortenText(item?.name)}
                            </span>
                          </Tooltip>
                        </a>
                      </Link>
                    </div>
                    <div
                      className='flex w-1/6 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                      role='cell'
                    >
                      {price ? (
                        <span className='flex items-center space-x-1 font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
                          <ChainIcon width={16} name={chain} tooltip={getTokenName(chain)} />
                          <span>{price ? numFormatter(price, 4) : '-'}</span>
                        </span>
                      ) : (
                        <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
                          -
                        </span>
                      )}
                    </div>
                    <div
                      className='flex w-1/6 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                      role='cell'
                    >
                      <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
                        <ShowUser
                          collection={fromCollection}
                          user={fromUser}
                          address={from}
                          showName={true}
                        />
                      </span>
                    </div>
                    <div
                      className='flex w-1/6 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                      role='cell'
                    >
                      <span className='font-display text-sm font-semibold text-jacarta-700 dark:text-white'>
                        <ShowUser
                          collection={toCollection}
                          user={toUser}
                          address={to}
                          {...(eventType === 'like' && {
                            item: {
                              ...item,
                              collectionAddress,
                              itemId,
                            },
                          })}
                          showName={true}
                        />
                      </span>
                    </div>
                    <div
                      className='flex w-1/6 items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                      role='cell'
                    >
                      {eventType === 'sale' || eventType == 'transfer' || eventType == 'changed' ? (
                        <Link href={`${getSupportedChain(chain)?.scan_url}/tx/${txHash}`}>
                          <a
                            className='flex flex-wrap items-center text-accent'
                            target='_blank'
                            rel='nofollow noopener'
                            data-tippy-content='March 13 2022, 2:32 pm'
                          >
                            <Tooltip
                              title={new Intl.DateTimeFormat('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                              }).format(new Date(createdAt))}
                            >
                              <span className='flex items-center font-display text-sm font-semibold text-accent hover:text-accent-light dark:text-jacarta-200 dark:hover:text-white'>
                                {diffMins < 60
                                  ? `${diffMins} m ago`
                                  : diffHours < 24
                                  ? `${diffHours} h ago`
                                  : diffDays < 7
                                  ? `${diffDays} d ago`
                                  : diffWeeks < 4
                                  ? `${diffWeeks} w ago`
                                  : diffMonths < 12
                                  ? `${diffMonths} mo ago`
                                  : `${diffYears} y ago`}
                                <svg
                                  className='ml-1 w-[15px] pb-1'
                                  xmlns='http://www.w3.org/2000/svg'
                                  viewBox='0 0 24 24'
                                  strokeWidth='2px'
                                >
                                  <defs />

                                  <line
                                    x1='9.25'
                                    y1='14.523'
                                    x2='23.25'
                                    y2='0.75'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <polyline
                                    points='23.25 8.621 23.25 0.75 15.25 0.75'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <path
                                    d='M12.125,5.75H1.625a.875.875,0,0,0-.875.875v15.75a.875.875,0,0,0,.875.875h15.75a.875.875,0,0,0,.875-.875v-10.5'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                </svg>
                              </span>
                            </Tooltip>
                          </a>
                        </Link>
                      ) : (
                        <Tooltip
                          title={new Intl.DateTimeFormat('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true,
                          }).format(new Date(createdAt))}
                        >
                          <span className='mr-1'>
                            {diffMins < 60
                              ? `${diffMins} m ago`
                              : diffHours < 24
                              ? `${diffHours} h ago`
                              : diffDays < 7
                              ? `${diffDays} d ago`
                              : diffWeeks < 4
                              ? `${diffWeeks} w ago`
                              : diffMonths < 12
                              ? `${diffMonths} mo ago`
                              : `${diffYears} y ago`}
                          </span>
                        </Tooltip>
                      )}
                    </div>

                    <div
                      className='flex w-[60px] items-center border-t border-jacarta-100 py-4 px-4 dark:border-jacarta-600'
                      role='cell'
                    >
                      <span className='ml-auto text-accent hover:text-accent-light dark:text-jacarta-200 dark:hover:text-white'>
                        {isConnected &&
                          activity?.from === walletAddress &&
                          order &&
                          ['bid', 'listing'].includes(order.eventType) &&
                          !order.isExpired &&
                          order.status === 'not processed' && (
                            <Tooltip title='Cancel Order'>
                              <button
                                onClick={() => {
                                  const { signature, chain, orderId, kind } = order;
                                  return dispatch(
                                    setModal({
                                      // key: 'cancelModal',
                                      key:
                                        order.eventType === 'listing'
                                          ? 'cancelSellModal'
                                          : 'cancelModal',
                                      data: {
                                        signature,
                                        id: orderId,
                                        kind,
                                        chain,
                                        other: {
                                          ...item,
                                          collectionAddress,
                                          itemId,
                                        },
                                        collection: collectionInfo,
                                      },
                                    }),
                                  );
                                }}
                              >
                                <RiDeleteBin2Line size={20} />
                              </button>
                            </Tooltip>
                          )}
                      </span>
                    </div>
                  </div>
                );
              })}
              {!allActivities && !isError ? <ActivitySkeleton count={10} /> : null}
            </div>
            <OnEnterScreen
              onVisible={() => {
                setShouldLoad({});
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Activity_item;
