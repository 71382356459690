import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { useContext } from 'react';
import { DataRefreshContext } from '../components/refreshContext';

export default function useGetActivities(
  sortBy = 'updatedAt:desc',
  limit = 20,
  page = 1,
  eventType,
  address,
  collectionAddress,
  itemId,
  selectedChains,
) {
  const { refreshDate } = useContext(DataRefreshContext);
  let config;
  config = {
    url: `${constants.api.url_new}/items/getItemActivities`,
    method: constants.httpMethods.get,
    params: {
      sortBy,
      limit,
      page,
      eventType,
      address,
      collectionAddress,
      itemId,
      selectedChains,
      refreshDate,
    },
  };
  const fetcher = () =>
    axios(config).then((response) => {
      return response.data;
    });
  return useAdaptor(axios.getUri(config), fetcher);
}
